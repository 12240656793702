import * as React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

import { primary } from '@/components/colors';
import { size } from '@/components/mq';
import { angleDown, angleUp } from '@/images/icons';

const sections: Record<string, string> = {
  factsheet: 'Factsheet',
  description: 'Description',
  history: 'History',
  features: 'Features',
  videos: 'Videos',
  images: 'Images',
  logo: 'Logo',
  monetization: 'Monetization',
  team: 'Team',
  contact: 'Contact',
};

const PresskitNavbar = styled.nav`
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 16px;
  overflow: hidden;

  ${size.medium} {
    align-self: start;
    margin-right: 16px;
    min-width: 240px;
    position: sticky;
    top: 16px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    background: white;
    margin-bottom: 16px;
  }
`;

const PresskitNavbarHeader = styled.div`
  padding: 8px 16px;
  background-color: #ededed;
  /* border-bottom: 3px solid #ccc; */
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

type PresskitNavbarListProps = { open: boolean };
const PresskitNavbarList = styled.ul`
  display: ${(props: PresskitNavbarListProps) =>
    props.open ? 'flex' : 'none'};
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: column;
  padding: 4px;

  li a {
    display: block;
    padding: 8px 12px;
    color: black;
    text-decoration: none;
    border-radius: 4px;

    &:hover {
      background-color: ${primary};
      color: white;
    }
  }
`;

const CollapseIcon = styled.img`
  height: 18px;
  display: block;
`;

export const PresskitNav = () => {
  const [open, setOpen] = React.useState(true);

  return (
    <PresskitNavbar>
      <PresskitNavbarHeader
        onClick={() => setOpen(!open)}
        role="button"
        aria-disabled="false"
        aria-expanded={open}
      >
        <span>Presskit</span>
        {open ? (
          <CollapseIcon src={angleUp} alt="Collapse" />
        ) : (
          <CollapseIcon src={angleDown} alt="Open" />
        )}
      </PresskitNavbarHeader>
      <PresskitNavbarList open={open}>
        {Object.entries(sections).map(([slug, name]) => (
          <li>
            <Link to={`#${slug}`}>{name}</Link>
          </li>
        ))}
      </PresskitNavbarList>
    </PresskitNavbar>
  );
};
