/** @jsx jsx */
/** @jsxFrag React.Fragment */
import * as React from 'react';
import { Helmet } from 'react-helmet';
import SiteHeader from '@/components/SiteHeader';
import { HeroHeaderSection, HeroHeaderTitle } from '@/components/HeroHeader';
import { logo, screenshots } from '@/images/double-turn';

import { SiteFooter } from '@/components/SiteFooter';
import { SectionContainer } from '../components/layout/SectionContainer';
import { SectionContent } from '../components/layout/SectionContent';
import styled from '@emotion/styled';
import { size } from '@/components/mq';
import { PresskitNav } from '@/components/presskit/PresskitNav';
import { Link } from 'gatsby';
import { css, jsx } from '@emotion/react';
import { primary } from '../components/colors';

const data = {
  factsheet: {
    developer: {
      name: 'Inwave Labs',
      website: 'https://inwavelabs.com',
    },
    release: 'November 6, 2020',
    website: 'https://doubleturngame.com',
    platforms: [
      { name: 'Steam', link: '' },
      { name: 'itch.io', link: '' },
    ],
    price: '$4.99 USD',
  },
};

const PresskitSectionContainer = styled(SectionContainer)`
  background-color: white;
  color: black;
`;

const PresskitSection = styled(SectionContent)`
  ${size.medium} {
    flex-direction: row;
  }
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const InfoCard = ({
  children,
  label,
}: React.PropsWithChildren<{ label: string }>) => (
  <div>
    <strong>{label}</strong>
    <p
      css={css`
        margin-top: 0;
      `}
    >
      {children}
    </p>
  </div>
);

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  img {
    width: 100%;
    display: block;

    &:hover {
      transition: outline 0.2s;
      outline: 4px solid ${primary};
    }
  }
`;

const VideoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  ${size.medium} {
    grid-template-columns: 1fr 1fr;
  }

  iframe {
    height: 100%;
    width: 100%;
    aspect-ratio: 16/9;
  }
`;

const PresskitPage = () => {
  return (
    <>
      <SiteHeader />
      <main>
        <Helmet>
          <title>Double Turn</title>
        </Helmet>
        <HeroHeaderSection>
          <HeroHeaderTitle>Presskit</HeroHeaderTitle>
        </HeroHeaderSection>
        <PresskitSectionContainer>
          <PresskitSection>
            <PresskitNav />
            <div>
              <section>
                <h2 id="factsheet">Factsheet</h2>
                <InfoGrid>
                  <InfoCard label="Developer">
                    <a href="https://inwavelabs.com">Inwave Labs</a>
                  </InfoCard>
                  <InfoCard label="Location">Hoboken, NJ</InfoCard>
                  <InfoCard label="Release Date">November 6, 2020</InfoCard>
                  <InfoCard label="Website">
                    <Link to="/">https://doubleturngame.com</Link>
                  </InfoCard>
                  <InfoCard label="Platforms">
                    <div>
                      <a href="http://store.steampowered.com/app/762680/Double_Turn">
                        Steam
                      </a>
                    </div>
                    <div>
                      <a href="https://inwave.itch.io/double-turn">itch.io</a>
                    </div>
                  </InfoCard>
                  <InfoCard label="Price">$4.99 USD</InfoCard>
                </InfoGrid>
              </section>
              <section>
                <h2 id="description">Description</h2>
                <p>
                  <strong>
                    <em>Double Turn</em> is a pro-wrestling party brawler with
                    fast arcade fighting.{' '}
                  </strong>{' '}
                  Hop into the ring and master the simple melee combat in 2-4
                  player local versus matches.
                </p>
                <p>
                  The core combat is simple: strike, grab, throw, and block.
                  Turn up the heat and charge up your signature move to takedown
                  your opponent in style. The gameplay is quick to pick up and
                  easy to learn. Strike to throw powerful fists and quick
                  elbows, block to defend against attacks, and throw your foe
                  with classic wrestling maneuvers. Fighting builds heat, heat
                  unlocks your signature, and signatures get you championships.
                </p>
                <p>
                  Brawl in 2-4 player local versus modes inspired by wrestling
                  gimmick matches. Mix up the mayhem by introducing extreme ring
                  hazards like tables, ladders, and cages. Or go it alone and
                  test your skills in the solo challenges: Battle Royal and
                  Gauntlet.
                </p>
                <p>
                  Grapple with one of four wrestlers: the high flying masked
                  luchadora underdog, the tough-as-nails street fighting heel,
                  the fierce master of strong style, and the fan favorite
                  babyface.
                </p>
              </section>
              <section>
                <h2 id="history">History</h2>
                <p>
                  <em>Double Turn</em> was started as a one-hit-kill fighting
                  game prototype in early 2017 with the aim of fusing the quick
                  fast-paced gameplay of platform fighters and the allure of
                  professional wrestling. <em>Double Turn</em> takes from the
                  wrestling archetypes, gimmicks, and movesets to create a
                  hand-to-hand arena fighter. Everything from the "heat"
                  mechanic to the character names were born out of a love of
                  wrestling and bringing the feeling of a match to the screen.
                </p>
                <p>
                  We released <em>Double Turn</em> on Steam in Early Access on
                  April 12, 2018. After a few months of development and a hiatus
                  in 2019, development resumed in 2020 and the full release of
                  Double Turn landed on November 6, 2020.
                </p>
              </section>
              <section>
                <h2 id="features">Features</h2>
                <ul>
                  <li>Fast-paced platform fighter</li>
                  <li>Melee-focused arcade combat</li>
                  <li>Brawl in 2-4 player local versus matches</li>
                  <li>
                    Test your skills in solo challenge modes
                    <ul>
                      <li>
                        Battle Royal: Survive in the ring against waves of 20
                        other fighters
                      </li>
                      <li>
                        Gauntlet: Fight against the full roster in back-to-back
                        matches
                      </li>
                    </ul>
                  </li>
                  <li>Take the mantle of one of four pro-wrestlers</li>
                </ul>
              </section>
              <section>
                <h2 id="videos">Videos</h2>
                <VideoGrid>
                  <iframe
                    title="Double Turn trailer on YouTube"
                    width="480"
                    height="270"
                    src="https://www.youtube.com/embed/ZNF3iyz4bVw?rel=0&amp;fs=0&amp;modestbranding=1"
                    frameBorder="0"
                    allow="encrypted-media"
                    allowFullScreen
                  />
                </VideoGrid>
              </section>
              <section>
                <h2 id="images">Images</h2>
                <ImageGrid>
                  {screenshots.map((screenshot, index) => (
                    <a href={`/images/double-turn/screenshot-${index + 1}.png`}>
                      <img src={screenshot} />
                    </a>
                  ))}
                </ImageGrid>
              </section>
              <section>
                <h2 id="logo">Logo</h2>
                <a href={'/images/double-turn/logo.png'}>
                  <img src={logo} />
                </a>
              </section>
              <section>
                <h2 id="monetization">Monetization</h2>
                <p>
                  <strong>Inwave Labs, LLC</strong> hereby grants permission for
                  the contents of <em>Double Turn</em> to be published through
                  video broadcasting services for any commercial or
                  non-commercial purposes. Creators may monetization videos
                  created containing assets from <em>Double Turn</em>, including
                  video and audio obtained in-game. This permission can be found
                  in writing at{' '}
                  <a href="https://doubleturngame.com/presskit">
                    https://doubleturngame.com/presskit
                  </a>
                  .
                </p>
              </section>
              <section>
                <h2 id="team">Team</h2>
                <InfoGrid>
                  <InfoCard label="Kenny Goff">
                    <div>Design &amp; Programming</div>
                    <a href="https://twitter.com/kennygoff">@kennygoff</a>
                  </InfoCard>
                  <InfoCard label="Phil Giarrusso">
                    <div>Art &amp; Animation</div>
                    <a href="https://twitter.com/rephildesign">@rephildesign</a>
                  </InfoCard>
                  <InfoCard label="Orb Soundwerx">
                    <div>Audio Production</div>
                    <a href="https://twitter.com/orbsoundwerx">@orbsoundwerx</a>
                  </InfoCard>
                </InfoGrid>
              </section>
              <section>
                <h2 id="contact">Contact</h2>
                <InfoGrid>
                  <InfoCard label="Press contact">
                    <a href="mailto:press@inwavelabs.com">
                      press@inwavelabs.com
                    </a>
                  </InfoCard>
                  <InfoCard label="General inquiries">
                    <a href="mailto:hello@inwavelabs.com">
                      hello@inwavelabs.com
                    </a>
                  </InfoCard>
                  <InfoCard label="Twitter">
                    <a href="https://twitter.com/inwavelabs">@inwavelabs</a>
                  </InfoCard>
                  <InfoCard label="Support">
                    <a href="mailto:support@inwavelabs.com">
                      support@inwavelabs.com
                    </a>
                  </InfoCard>
                </InfoGrid>
              </section>
            </div>
          </PresskitSection>
        </PresskitSectionContainer>
      </main>
      <SiteFooter />
    </>
  );
};

export default PresskitPage;
